<template>
<div>
    <div class="pb-2">
        <TheHeader />

    </div>
    <div class="main-background">
        <CContainer>
            <CRow>
                <CCol md="6">
                    <CContainer class="mobile-text-align">
                        <h1>Connect With Us</h1>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, </p>

                        <CInput label="Name" placeholder="Enter your name"></CInput>
                        <CInput label="Email Address" placeholder="Enter your Email Address"></CInput>
                        <CTextarea label="Message" placeholder="Enter your Message here..."></CTextarea>
                        <CButton color="primary" size="lg" block>Submit</CButton>
                    </CContainer>
                </CCol>

                <CCol md="6">
                    <CContainer class="mobile-text-align">
                        <h1 class="mobile-margin">Hotline</h1>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, </p>

                        <CRow class="pt-4">
                            <CCol md="6">
                                <h3>Genarals</h3>
                                <p ><a class="text-white" href="tel:011-2225444">011-2225444</a></p>
                            </CCol>
                            <CCol md="6">
                                <h3>Mobile</h3>
                                 <p ><a class="text-white" href="tel:077-7700644">077-7700644</a></p>
                            </CCol>
                        </CRow>
                         <CRow class="pt-4">
                            <CCol md="6">
                                <h3>Email</h3>
                                <a class="text-white" href = "sales.scanme@gmail.com">Sales.scanme@gmail.com</a>
                            </CCol>
                            <CCol md="6">
                                <h3>Address</h3>
                                <p>No.131, Orex City, Ekala, Ja-ela.</p>
                            </CCol>
                        </CRow>
                         
                    </CContainer>
                </CCol>
            </CRow>
        </CContainer>

    </div>

    <div>
        <TheFooter />

    </div>

</div>
</template>

<script>
import TheHeader from '../../../containers/TheHeader.vue'
import TheFooter from '../../../containers/TheFooter.vue'

export default {
    components: {
        TheHeader,
        TheFooter
    }
}
</script>

<style scoped>
.main-background {
    min-height: 80vh;
    background-color: #09092F;
    color: #ffffff;
    padding-top: 110px;
}


@media (max-width: 575.98px) {

.mobile-margin{
    padding-top: 50px;
}

.mobile-text-align{
    text-align: center;
}
}
</style>
